@import '../../styles/variables';
@import '../../styles/mixin';

.footer__flex_column {
  display: flex;
  flex-direction: column;
}

.footer__flex_row {
  display: flex;
  flex-direction: row;
}

.footer_font {
  line-height: 20px;
  font-size: $text-sm !important;
}

.align-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include media(sm) {
    align-items: flex-start;
  }
}

.footer {
  position: relative;
  z-index: 2;
  border-top: $border-top;
  padding-top: 48px;
  padding-bottom: 48px;
  font-size: $text-base;
  color: $color-text-footer;
  background: $background-footer;
  background-image: $footer-background-image;
  font-weight: $footer-font-weight-text;
  overflow: hidden;

  .footer__container {
    @extend .footer__flex_column;
    row-gap: 40px;
    width: 100%;

    @include media(2xl) {
      row-gap: 48px;
    }
  }

  p,
  a,
  span {
    letter-spacing: -0.02px;
  }

  figure {
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
  }

  .logo {
    @extend .align-logo;
    position: relative;
    width: 100%;
    height: $footer-logo-max-height;
    max-width: $footer-logo-max-width;

    figure {
      position: relative;

      img {
        max-height: $footer-logo-max-height;
        width: auto;
      }
    }
  }

  .footer__info_legal {
    @extend .footer__flex_column;
    row-gap: 8px;
    width: 100%;

    p,
    li {
      font-size: $text-sm;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      column-gap: 20px;
      row-gap: 4px;

      @include media(xl){
        column-gap: 28px;
      }
        
      li {
        position: relative;

        &:not(:last-child)::after {
          content: ' | ';
          position: absolute;
          display: block;
          opacity: 0.7;
          right: -12px;
          top: 0;
          color: darken($color-text-footer, 50%) !important;

          @include media(xl){
            right: -16px;
          }
        }
      }
    }

  }

  .copyright {
    align-items: flex-end;
    width: 100%;

    @include media(md) {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
  }

}

.footer_empty {
  @extend .footer__flex_column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 4px;
  padding: 20px;
  font-size: $text-base;
  color: $color-text-footer;
  background: $background-footer;
  font-size: $text-sm;
  min-height: 120px;

  h3 {
    font-size: $text-base;
    font-weight: 700;
  }

  @include media(sm) {
    padding: 40px;

    br {
      display: none;
    }
  }
}